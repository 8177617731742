// Saved episodes functionality
document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('.c-episode-saver').forEach(item => {
    item.addEventListener('click', function(e) {
      e.preventDefault();
      var episodeId = this.getAttribute('data-episode-id');

      fetch('/actions/my-module/episode/save-episode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfTokenValue
        },
        body: JSON.stringify({ episodeId: episodeId })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data.success) {
          if (data.isSaved) {
            item.classList.add('is-saved');
            item.querySelector('.c-episode-saver__saved').style.display = 'block';
            item.querySelector('.c-episode-saver__unsaved').style.display = 'none';
            item.querySelector('.text-base').textContent = 'Saved';
          } else {
            item.classList.remove('is-saved');
            item.querySelector('.c-episode-saver__saved').style.display = 'none';
            item.querySelector('.c-episode-saver__unsaved').style.display = 'block';
            item.querySelector('.text-base').textContent = 'Save Episode';
          }
        } else {
          console.log('Failed to update');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    });
  });
});

function toggleClasses(element) {
  const togglePrefix = element.dataset.prefix || 'this';
  let toggled = null;

  // Check if the clicked element or any of its parents is a link
  let target = event.target;
  while (target !== element) {
    if (target.tagName === 'A') {
      return; // It's a link, so just return without toggling the dropdown
    }
    target = target.parentNode;
}

  // If the element you need toggled is relative to the toggle, add the
  // .js-this class to the parent element and "this" to the data-toggled attr.
  if (element.dataset.toggled == "this") {
    toggled = [...element.closest('.js-this')];
  }
  else {
    toggled = [...document.querySelectorAll(element.dataset.toggled)];
  }
  if (element.getAttribute('aria-expanded') == 'true') {
    element.setAttribute('aria-expanded', 'true');
  }
  else {
    element.setAttribute('aria-expanded', 'false');
  }
  element.classList.toggle(togglePrefix + '-is-active');

  if (toggled && toggled.length) {
    toggled.forEach((el) => {
      el.classList.toggle(togglePrefix + '-is-active');
    })
  }

  // Remove a class on another element, if needed.
  if (element.dataset.removeClass) {
    document.querySelector('.' + element.dataset.removeClass).classList.remove(element.dataset.removeClass);
  }

  // Check if .c-nav .c-lines has the class 'this-is-active' and toggle class on HTML
  setTimeout(function() {
    const navLines = document.querySelector('.c-nav .lines');
    if (navLines && navLines.classList.contains(togglePrefix + '-is-active')) {
      document.querySelector('.c-site-wrapper-container').classList.add('mobile-nav-is-active');
      // Scroll to the top of the page
      window.scroll(0, 0);
      document.body.scrollTop = 0; // For Safari on iOS
      document.querySelector('.c-site-wrapper-container').scrollTop = 0; // For Chrome, Firefox, IE, and Opera
    } else {
      document.querySelector('.c-site-wrapper-container').classList.remove('mobile-nav-is-active');
    }
  }, 30);
}

function setUtilities(parentEl) {
  // Toggle class
  [...parentEl.querySelectorAll('.js-toggle:not(.js-toggle--initialized)')].forEach((el) => {
    el.classList.add('js-toggle--initialized');
    el.addEventListener('click', (e) => {
        if (!el.classList.contains('js-not-stop')) {
          e.stopPropagation();
        }
        toggleClasses(el, e);
    });
});

  // Toggle parent class
  [...parentEl.querySelectorAll('.js-toggle-parent:not(.js-toggle-parent--initialized)')].forEach((el) => {
    el.classList.add('js-toggle-parent--initialized');
    el.addEventListener('click', (e) => {
      if (!el.classList.contains('js-not-stop')) {
        e.preventDefault();
      }
      el.classList.toggle('this-is-active');
      el.parentElement.classList.toggle('this-is-active');
    });
  });
}

setUtilities(document);

// Glide slider

// Select all Glide sliders on the page
const glides = document.querySelectorAll('.glide');

glides.forEach((glide) => {
  const slideCount = glide.querySelectorAll('.glide__slide').length;
   let perViewSetting = 7;

   if (slideCount < 7) {
      perViewSetting = slideCount;
   }

   new Glide(glide, {
      // Your common options for all sliders
      type: 'slider',
      perView: perViewSetting,
      gap: 25,
      bound: true,
      breakpoints: {
        1200: {
          perView: 5 // Adjust for large screens
        },
        800: {
          perView: 3 // Adjust for medium screens
        },
        480: {
          perView: 2 // Adjust for small screens
        }
     }
   }).mount();
});

// Select all Glide sliders on the page
const glidesMegaNav = document.querySelectorAll('.glide-mega-nav');

// Loop through each slider and initialize it
glidesMegaNav.forEach((glide) => {
   new Glide(glide, {
      // Your common options for all sliders
      type: 'slider',
      perView: 1,
      gap: 25
      // ... any other options ...
   }).mount();
});

// // Select all Glide sliders on the page
// const glidesNewEpisodes = document.querySelectorAll('.glide-new-episodes');

// // Loop through each slider and initialize it
// glidesNewEpisodes.forEach((glide) => {
//    new Glide(glide, {
//       // Your common options for all sliders
//       type: 'slider',
//       perView: 1,
//       gap: 25
//       // ... any other options ...
//    }).mount();
// });

// Wait until the window and all its resources are fully loaded
window.onload = function() {
  // Select all Glide sliders on the page
  const glidesNewEpisodes = document.querySelectorAll('.glide-new-episodes');

  // Loop through each slider and initialize it
  glidesNewEpisodes.forEach((glide) => {
    const glideInstance = new Glide(glide, {
      type: 'slider',
      perView: 1,
      gap: 25
      // Add other options here as needed
    });

    glideInstance.on('mount.after', function () {
      // Adjust the height initially after Glide is mounted
      adjustSliderHeight(glide);
    });

    glideInstance.on('run.after', function () {
      // Adjust the height after each slide change
      adjustSliderHeight(glide);
    });

    glideInstance.mount(); // Initialize Glide
  });

  function adjustSliderHeight(glide) {
    // Select the current active slide
    const currentSlide = glide.querySelector('.glide__slide--active');

    if (currentSlide) {
      // Get the height of the active slide
      const slideHeight = currentSlide.offsetHeight;

      // Set the height of the Glide track to match the active slide
      const glideTrack = glide.querySelector('.glide__track');
      if (glideTrack) {
        glideTrack.style.height = `${slideHeight}px`;
      }
    }
  }
};

document.addEventListener('DOMContentLoaded', () => {
  const navItems = document.querySelectorAll('.c-nav__item--mega-nav');
  const megaNavs = document.querySelectorAll('.c-mega-nav');

  function clearActiveClasses() {
    navItems.forEach(item => item.classList.remove('is-active'));
    megaNavs.forEach(nav => nav.classList.remove('is-active'));
  }

  function toggleNavItemAndMegaNav(clickedNavItem, targetMegaNavSelector) {
    // Check if the clicked item is already active
    const isActive = clickedNavItem.classList.contains('is-active');

    // Clear all active classes first
    clearActiveClasses();

    // If the clicked item was not active before, activate it and its corresponding mega nav
    if (!isActive) {
      clickedNavItem.classList.add('is-active');
      const targetMegaNav = document.querySelector(targetMegaNavSelector);
      if (targetMegaNav) {
        targetMegaNav.classList.add('is-active');
      }
    }
  }

  // Attach event listeners to nav items
  navItems.forEach(item => {
    item.addEventListener('click', (e) => {
      // Prevent default link behavior
      e.preventDefault();

      // The mega nav to toggle is based on the clicked item's class
      if (item.classList.contains('c-nav__item--discover')) {
        toggleNavItemAndMegaNav(item, '.c-mega-nav--discover');
      } else if (item.classList.contains('c-nav__item--partners')) {
        toggleNavItemAndMegaNav(item, '.c-mega-nav--partners');
      } else {
        // For other nav items that don't have a mega nav
        clearActiveClasses();
        item.classList.add('is-active');
      }
    });
  });

  // Clicking outside of nav items or mega navs clears the active class
  document.addEventListener('click', (e) => {
    if (!e.target.closest('.c-nav') && !e.target.closest('.c-mega-nav')) {
      clearActiveClasses();
    }
  });
});

document.addEventListener('DOMContentLoaded', function () {
  var popupContainer = document.querySelector('.c-popup-container');

  // Proceed only if popupContainer exists
  if (popupContainer) {
    var popup = popupContainer.querySelector('.c-popup');
    var closeButton = popupContainer.querySelector('.c-popup__close');

    // Function to check if the cookie exists
    function checkCookie(name) {
      var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
      if (match) {
        console.log("Popup cookie found");
        return true;
      }
      console.log("Popup cookie not found");
      return false;
    }

    // Function to show the popup
    function showPopup() {
      popupContainer.style.display = 'block';
    }

    // Function to hide the popup without setting a cookie
    function hidePopup() {
      popupContainer.style.display = 'none';
    }

    // Function to hide the popup and set a cookie
    function hidePopupAndSetCookie() {
      popupContainer.style.display = 'none';
      var expires = new Date();
      expires.setTime(expires.getTime() + (7 * 24 * 60 * 60 * 1000)); // Expires in 7 days
      // expires.setTime(expires.getTime() + (30 * 24 * 60 * 60 * 1000)); // Expires in 30 days
      // expires.setTime(expires.getTime() + (10 * 1000)); // For testing purposes
      document.cookie = "popupClosed=true; expires=" + expires.toUTCString() + "; path=/";
    }

    // Only show popup if the cookie does not exist
    if (!checkCookie('popupClosed')) {
      showPopup();
    }

    // Hide popup and set cookie when close button is clicked
    closeButton.addEventListener('click', function () {
      hidePopupAndSetCookie();
    });

    // Modify here to set cookie when clicking outside of it
    document.addEventListener('click', function (event) {
      if (!popup.contains(event.target) && event.target !== closeButton) {
        hidePopupAndSetCookie();
      }
    });

    // Prevent popup from closing when clicking inside it
    popup.addEventListener('click', function (event) {
      event.stopPropagation();
    });
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const tabs = document.querySelectorAll('.c-mega-nav__tabs-list li');
  const tabContents = document.querySelectorAll('.c-mega-nav__tab-content');

  // Function to clear all active tabs and hide all tab contents
  function resetTabs() {
    tabs.forEach(tab => tab.classList.remove('is-active'));
    tabContents.forEach(content => content.style.display = 'none');
  }

  // Function to activate a tab and show its content
  function activateTab(tabElement) {
    const tabNumber = tabElement.getAttribute('data-tab');
    const tabContent = document.querySelector(`.c-mega-nav__tab-content[data-tab-content="${tabNumber}"]`);
    
    resetTabs();
    tabElement.classList.add('is-active');
    if (tabContent) {
      tabContent.style.display = 'block';
    }
  }

  // Set the first tab as active
  if (tabs.length > 0) {
    activateTab(tabs[0]);
  }

  // Add click event listeners to tabs
  tabs.forEach(tab => {
    tab.addEventListener('click', (e) => {
      e.preventDefault();
      activateTab(tab);
    });
  });
});

// Copy Link
// Select all elements with the class 'copyLink'
var elements = document.getElementsByClassName('o-copy-link');

// Loop through the NodeList and attach the event listener to each element
for (var i = 0; i < elements.length; i++) {
  elements[i].addEventListener('click', function() {
    var url = window.location.href; // This gets the current URL
    var dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.value = url;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    alert('URL copied to clipboard!');
  });
}

document.addEventListener('DOMContentLoaded', function () {
  const accordion = document.querySelector('.c-accordion');

  if (accordion) {
    accordion.addEventListener('click', function (e) {
      // Check if the clicked element is an accordion heading
      if (e.target.closest('.c-accordion-item__heading')) {
        const item = e.target.closest('.c-accordion-item');
        const content = item.querySelector('.c-accordion-item__content');

        // Toggle the open class
        item.classList.toggle('is-open');

        // Animate height
        if (item.classList.contains('is-open')) {
          content.style.maxHeight = content.scrollHeight + 'px';
        } else {
          content.style.maxHeight = 0;
        }
      }
    });
  }
  
});
